import { graphql, useStaticQuery } from "gatsby"
import React from "react"
import { FrontpageQuery } from "../../graphql-types"
import Filter from "../components/Filter"
import Layout from "../components/Layout"
import { FrontpageFromQuery } from "../types"
import { Props } from "../utils/context"
import { Lang, simpleLang } from "../utils/i18n"

export const frontpageQuery = graphql`
  query Frontpage {
    prismic {
      allFrontpages {
        edges {
          node {
            slogan
            description
            _meta {
              id
              uid
              lang
            }
          }
        }
      }
    }
  }
`

export default function Home({ pageContext }: Props) {
  const queryResult = useStaticQuery(frontpageQuery) as FrontpageQuery

  const slogan = getSlogan(queryResult, pageContext.lang)
  const description = getDescription(queryResult, pageContext.lang)

  return (
    <Layout
      isFrontpage={true}
      lang={pageContext.lang}
      description={description}
    >
      <div className="background-container bg-cover bg-center bg-fixed z-0">
        <div className="slogan text-center sticky top-0 flex flex-col justify-center h-screen -mt-32">
          <h1 className="text-4xl md:text-6xl mt-slogan-small md:mt-slogan-full low:slogan-up">
            {slogan}
          </h1>
          <h1 className="slogan-reflect text-4xl md:text-6xl select-none">
            {slogan}
          </h1>
        </div>
        <div className="search-container pb-8">
          <div className="bg-white pt-4 rounded-2xl shadow-lg">
            <Filter lang={pageContext.lang} />
          </div>
        </div>
      </div>
    </Layout>
  )
}

function getSlogan(queryResult: FrontpageQuery, lang: Lang) {
  return getFrontpage(queryResult, lang).map(
    (frontpage: any) => frontpage?.node.slogan[0].text as string
  )[0]
}

function getDescription(queryResult: FrontpageQuery, lang: Lang) {
  return getFrontpage(queryResult, lang).map(
    (frontpage: any) => frontpage?.node.description[0]?.text as string
  )[0]
}

function getFrontpage(queryResult: FrontpageQuery, lang: Lang) {
  return queryResult.prismic.allFrontpages.edges?.filter(
    (edge: FrontpageFromQuery) => simpleLang(edge!.node._meta.lang) === lang
  )
}
